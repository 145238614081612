
import axios from "axios";
import {
  defineComponent,
  provide,
  reactive,
  ref,
  toRefs,
  watch,
  Ref,
} from "vue";
import PostText from "./PostText.vue";
import PostNodeHead from "./PostNodeHead.vue";
import store from "../store";
import { ElMessage } from "element-plus";
interface PostNode {
  id: number;
}
export default defineComponent({
  name: "PostNode",
  components: {
    PostText,
    PostNodeHead,
  },
  props: {
    postNode: Object,
    userId: Number,
  },
  setup(props) {
    let res = reactive({
      postList: [] as PostNode[],
    });
    let sageInfo = reactive({
      addInfo: "你要sage它吗？",
      addStatus: 0,
      subInfo: "你要反对sage它吗？",
      subStatus: 0,
    });
    if (props.userId !== undefined) {
      let addData = (props.postNode as { sageAddId: Array<Number> }).sageAddId;
      if (addData != null && addData.indexOf(props.userId) !== -1) {
        sageInfo.addInfo = "你要取消sage它吗";
        sageInfo.addStatus = 1;
      }
      let subData = (props.postNode as { sageSubId: Array<Number> }).sageSubId;
      if (subData != null && subData.indexOf(props.userId) !== -1) {
        sageInfo.subInfo = "你要取消反对sage它吗";
        sageInfo.subStatus = 1;
      }
    }
    let getPostNode = (postId: number) => {
      let ok = false;
      for (let i = 0; i < res.postList.length; i++) {
        if (res.postList[i]["id"] === Number(postId)) {
          ok = true;
          // 清除展示
          res.postList.splice(i, 1);
        }
      }
      if (ok === false) {
        // 缓存查找
        let post = store.getters.getPost(Number(postId));
        if (post === null) {
          axios.get("forum/get?postId=" + postId).then((response) => {
            res.postList.push(response.data.data);
            res.postList[res.postList.length - 1]["showStatus"] = true;
            store.commit("addPost", res.postList[res.postList.length - 1]);
          });
        } else {
          res.postList.push(post);
          res.postList[res.postList.length - 1]["showStatus"] = true;
        }
      }
    };
    let mediaUrl = ref([]);
    let getMediaUrl = () => {
      if (props.postNode !== undefined) {
        if (props.postNode.mediaUrl !== "") {
          mediaUrl.value = eval("(" + props.postNode.mediaUrl + ")");
        } else {
          mediaUrl.value = [];
        }
      }
    };
    getMediaUrl();
    provide("getPostNode", getPostNode);
    let sageAdd = (postId: number) => {
      axios.defaults.headers.common["Authorization"] =
        store.getters.getAuthToken;
      axios.get("forum/sage/add?postId=" + postId).then((response) => {
        if (response.data.data) {
          addMessage();
          (props.postNode as { sageAddCount: number }).sageAddCount += 1;
          if (sageInfo.subStatus == 1) {
            (props.postNode as { sageSubCount: number }).sageSubCount -= 1;
            sageInfo.subStatus = 0;
          }
          sageInfo.addStatus = 1;
        } else if (
          response.data.data !== undefined &&
          response.data.data === false
        ) {
          subMessage();
          (props.postNode as { sageAddCount: number }).sageAddCount -= 1;
          sageInfo.addStatus = 0;
        }
      });
    };
    let addMessage = () => {
      ElMessage("添加成功");
    };
    let subMessage = () => {
      ElMessage("取消成功");
    };
    let sageSub = (postId: number) => {
      axios.defaults.headers.common["Authorization"] =
        store.getters.getAuthToken;
      axios.get("forum/sage/sub?postId=" + postId).then((response) => {
        if (response.data.data) {
          addMessage();
          (props.postNode as { sageSubCount: number }).sageSubCount += 1;
          if (sageInfo.addStatus == 1) {
            (props.postNode as { sageAddCount: number }).sageAddCount -= 1;
            sageInfo.addStatus = 0;
          }
          sageInfo.subStatus = 1;
        } else if (
          response.data.data !== undefined &&
          response.data.data === false
        ) {
          subMessage();
          (props.postNode as { sageSubCount: number }).sageSubCount -= 1;
          sageInfo.subStatus = 0;
        }
      });
    };
    let deleteMessage = () => {
      ElMessage({
        message: "删除成功",
        type: "success",
      });
    };
    let recoverMessage = () => {
      ElMessage({
        message: "恢复成功",
        type: "success",
      });
    };
    let deleteStatus: Ref<boolean> = ref(
      props.userId == props.postNode?.userId && props.postNode?.status == 0
    );
    let recoverStatus: Ref<boolean> = ref(
      props.userId == props.postNode?.userId && props.postNode?.status == 2
    );
    // 删除帖子
    let deletePost = (postId: number) => {
      axios.defaults.headers.common["Authorization"] =
        store.getters.getAuthToken;
      axios.get("forum/delete/ownPost?postId=" + postId).then((response) => {
        if (response.data.data.status == true) {
          deleteStatus.value = false;
          recoverStatus.value = true;
          deleteMessage();
        }
      });
    };
    // 恢复帖子
    let recoverPost = (postId: number) => {
      axios.defaults.headers.common["Authorization"] =
        store.getters.getAuthToken;
      axios.get("forum/recover/ownPost?postId=" + postId).then((response) => {
        if (response.data.data.status == true) {
          deleteStatus.value = true;
          recoverStatus.value = false;
          recoverMessage();
        }
      });
    };
    watch(props, () => {
      getMediaUrl();
    });
    let userId = props.userId;
    return {
      sageInfo,
      ...toRefs(props),
      sageAdd,
      sageSub,
      ...toRefs(res),
      getPostNode,
      mediaUrl,
      userId,
      deletePost,
      recoverPost,
      deleteStatus,
      recoverStatus,
    };
  },
});
